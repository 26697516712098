import React, { useState } from "react"
import BlogApi from "../../graphql/blog"
import { Tab, Tabs } from "react-bootstrap"
import { BlogFeature, Heading, Layout, SEO } from "src/sws-ui"
const GetBlogData = props => {
  return (
    <div className="container-fluid">
      <div className={"row"}>
        {props.blogs.map((data, counter) => {
          return (
            <div className={"col-md-4 col-sm-12"}>
              <div style={{ gridColumnEnd: "span 1" }}></div>
              <BlogFeature
                heading={data.frontmatter.title}
                content={data.frontmatter.summary}
                category={data.frontmatter.category}
                image={
                  data.frontmatter.image.split("/")[
                  data.frontmatter.image.split("/").length - 1
                    ]
                }
                detailpagelink={
                  "/blogs/" + data.frontmatter.title.replace(/\s/g, "_")
                }
              ></BlogFeature>
              <div style={{ gridColumnEnd: "span 1" }}></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
const BlogLandingPage = () => {
  const BlogApidata1 = BlogApi()
  var BlogApidata = BlogApidata1.allMarkdownRemark.nodes.filter(
    data => data.frontmatter.category_language === "Thi"
  )

  const most_recent_blog = BlogApidata.length > 0 ? BlogApidata[0]: {}
  const [blogs, setBlogs] = useState(BlogApidata)
  const fetchValueFromKey = key => {
    setBlogs(
      BlogApidata.filter(data => data.frontmatter.category.toLowerCase() == key)
    )
  }
  return (
    <Layout>
      <SEO title="Blogs" />
      <div className={"container"}>
        <div className={"container-fluid"}>
          <Heading
            size="2"
            col="col-1-9"
            row="row-1"
            className="blog-title"
            style={{ marginTop: "50px" }}
          >
            บล็อก
          </Heading>
          {
            !!most_recent_blog.frontmatter && (
              <div className={"row-2"}>
                <div className={"col-md-6 col-sm-12"}>
                  <BlogFeature
                    heading={most_recent_blog.frontmatter.title}
                    content={most_recent_blog.frontmatter.summary}
                    col="col-1-7 col-md-center"
                    row="row-2 row-md-2"
                    category={most_recent_blog.frontmatter.category}
                    image={
                      most_recent_blog.frontmatter.image.split("/")[
                      most_recent_blog.frontmatter.image.split("/").length - 1
                        ]
                    }
                    detailpagelink={
                      "/blogs/" +
                      most_recent_blog.frontmatter.title.replace(/\s/g, "_")
                    }
                  />
                </div>
              </div>
            )
          }
          <div style={{ marginTop: "50px" }}>
            <Tabs defaultActiveKey="Culture" id="uncontrolled-tab-example">
              <Tab eventKey="Culture" title="แคมเปญ">
                <GetBlogData blogs={blogs.filter((blog) => blog.frontmatter.category.toLowerCase() === "culture")} />
              </Tab>
              <Tab eventKey="Event" title="กิจกรรม">
                <GetBlogData blogs={blogs.filter((blog) => blog.frontmatter.category.toLowerCase() === "event")} />
              </Tab>
              <Tab eventKey="Company" title="บริษัท">
                <GetBlogData blogs={blogs.filter((blog) => blog.frontmatter.category.toLowerCase() === "company")} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogLandingPage
